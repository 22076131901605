<template>
	<div class="index" >
		<!-- 注册 -->
		<div class="index2" >
			<div style="margin: 10px 0;" class="re_from">
				<div style="text-align: center;">注册</div>
				<div style="margin-top: 20px;">
					<input type="text" placeholder="用户名（请勿用电话、微信、QQ等号码注册）" class="re_input" v-model="username" />
				</div>
				<div style="margin-top: 10px;">
					<input type="password" placeholder="密码" class="re_input" v-model="password" />
				</div>
				<div style="margin-top: 10px;">
					<input type="password" placeholder="密码" class="re_input" v-model="againpassword" />
				</div>
				<div style="margin-top: 10px;">
					<input type="text" placeholder="邀请码" class="re_input" v-model="code" />
				</div>
				
			</div>
			
			<div style="display: flex;justify-content: center;">
				<div class="btn_login1" @click="register()"><span>注册</span></div>
				<!-- <button class="btn_blue_class" style="margin: 20px 0;max-width: 500px;" @click="register()"><span>注册</span></button> -->
			</div>
			<div style="display: flex;justify-content: center;">
				<div class="btn_login2" @click="back()"><span>返回</span></div>
				<!-- <button class="btn_blue_class" style="max-width: 500px;margin-bottom: 20px;background-color: #015DB4;" @click="back()"><span>返回</span></button> -->
			</div>
		</div>


	</div>
</template>

<script>
	import request from '@/utils/request'
	export default {
		name: 'register',
		data() {
			return {
				username: '',
				password: '',
				againpassword: '',
				code: '',
			};
		},
		activated() {
			//console.log('我这个页面显示就会执行');
			let _this = this;
			//获取邀请码
			let code = this.$route.query.code;
			if(code){
				_this.code = code;
			}
			
			//刷新
			if (_this.$route.params.refresh) {
				//_this.$router.go(0)
			}


		},
		mounted() {
			let _this = this;


		},

		methods: {
			//注册
			register(){
				let _this = this;
				let username = _this.username;
				let password = _this.password;
				let againpassword = _this.againpassword;
				let code = _this.code;
				if(username == ''){
					_this.$message({
					    message: _this.$t('notify.inputusername'),
					    type: 'warning'
					});
					return;
				}
				if(password == ''){
					_this.$message({
					    message: _this.$t('notify.inputpassword'),
					    type: 'warning'
					});
					return;
				}
				if(againpassword == ''){
					_this.$message({
					    message: _this.$t('notify.inputagainpassword'),
					    type: 'warning'
					});
					return;
				}
				if(password.length<6){
					_this.$message({
					    message: _this.$t('notify.inputpassword2'),
					    type: 'warning'
					});
					return;
				}
				if(againpassword.length<6){
					_this.$message({
					    message: _this.$t('notify.inputagainpassword2'),
					    type: 'warning'
					});
					return;
				}
				if(password != againpassword){
					
					_this.$message({
					    message: _this.$t('notify.inputpw'),
					    type: 'warning'
					});
					
					return;
				}
				request.post('finance/login/signup', {
					username:username,
					password:password,
					code:code,
				}).then(res => {
					//console.log(res)
					if(res.data.code == 0){
						_this.$message({
						    message: _this.$t('notify.signupsuccess'),
						    type: 'success'
						});
						window.sessionStorage.setItem('token',res.data.data.auth);
						this.$router.replace({ name: 'User', params: { refresh: true }})
					}else{
						_this.$message({
						    message: res.data.msg,
						    type: 'warning'
						});
					}
					
				});
			},
			
			//返回
			back(){
				this.$router.replace({ name: 'Login', params: { refresh: true }})
			}


		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
	.index{
		
		background:url(../assets/images/blockmoney/bg.png);
		background-size: cover;
		background-position: 50%;
		height: calc(100vh - 60px);
		display: flex;
		justify-content: center;
		/* align-items: center; */
	}
	.index2{
		border: 2px solid #6DB0C6;
		border-radius: 5px;
		background: linear-gradient(180deg, #0C4781, #051022 30%);
		padding: 10px ;
		width: 90%;
		max-width: 600px;
		height: 450px;
		margin-top: 70px;
		
	}
	.re_input {
		height: 36px;
		width: 100%;
		background-color: #021327;
		border: 2px solid #1184D2;
		border-radius: 5px;
		color: #fff;
		font-size: 14px;
		padding: 0 10px
	}
	
	.btn_blue_class {
		-webkit-box-align: center;
		align-items: center;
		border: 0px;
		border-radius: 5px;
		box-shadow: rgba(14, 14, 44, 0.4) 0px -1px 0px 0px inset;
		cursor: pointer;
		display: inline-flex;
		font-family: inherit;
		font-size: 14px;
		font-weight: 600;
		-webkit-box-pack: center;
		justify-content: center;
		letter-spacing: 0.03em;
		line-height: 1;
		opacity: 1;
		outline: 0px;
		transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
		height: 36px;
		padding: 0px 16px;
		background-color: #A76E12;
		color: white;
		width: 100%;
	}

	.re_from {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		grid-gap: 10px;
		/* margin-bottom: 10px; */
	}

	.re_laber_input {
		font-size: 21px;
		font-weight: 500;
		color: #fff;
		margin-bottom: 10px;
		display: block
	}


	.re_input:focus{
		border: 1px solid #596775;
	}
	.btn_login1{
		width: calc(100% - 5px);
		height: 30px;
		background:url(../assets/images/blockmoney/btn_login1.png);
		background-size: cover;
		background-position: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 14px;
		margin: 10px 0;
		max-width: 400px;
	}
	.btn_login2{
		width: calc(100% - 5px);
		height: 30px;
		background:url(../assets/images/blockmoney/btn_login2.png);
		background-size: cover;
		background-position: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 14px;
		margin: 10px 0;
		max-width: 400px;
	}
</style>
